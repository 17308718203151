<template>
  <div id="divRoot" class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-bind:logo="logoDark"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <transition name="fade-in-up">
          <router-view />
        </transition>

        <FooterExtended></FooterExtended>
      </div>
    </div>

    <div class="bottom-cookie" v-if="acceptedCookie === ''">
      <b-row class="text-center">
        <b-col cols="8" class="text-center m-5"><span style="font-size: 15px; color:white">{{ $t('cookie_description') }}</span></b-col>
        <b-col class="m-5">
          <v-btn text @click="setAcceptedCooke">
            <span style="font-size: 15px; color:white">OK</span>
          </v-btn>
        </b-col>
      </b-row>
		</div>

    <KTScrollTop></KTScrollTop>
  </div>
</template>

<style scoped>
  .bottom-cookie {
		position: fixed;
		z-index: 999;
		width: 100%;
		bottom: 0;
		background-color: black;
  }
</style>

<script>
import { mapGetters } from 'vuex';
import KTHeader from '@/view/layout/header/Header.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import FooterExtended from '@/view/layout/footer/FooterExtended.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTScrollTop from '@/view/layout/extras/ScrollTop';
import Loader from '@/view/content/Loader.vue';
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from '@/core/services/store/htmlclass.module.js';

import logoDark from '@/assets/logo/logo_dark.png';

import { firebaseAuth, firestore } from '../../main';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { collection, doc, query, where, onSnapshot, orderBy, limit } from 'firebase/firestore';
import { isFutureTimestamp } from '../../functions';

export default {
  name: 'Layout',
  components: {
    KTHeader,
    KTHeaderMobile,
    FooterExtended,
    KTScrollTop,
    Loader
  },
  computed: {
    ...mapGetters([
      'layoutConfig'
    ])
  },
  data() {
    return {
      logoDark,
      acceptedCookie: localStorage.getItem('eventboxAcceptedCookie') || '',
      snapMyInfo: null,
      snapEventList: null,
      snapMyBlockList: null,
      snapServiceCharge: null,
      snapEmployeeList: null,
    }
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    if (firebaseAuth.currentUser) {
      this.loadAllData();
    } else {
      signInWithEmailAndPassword(firebaseAuth, 'guest@eventbox.com', 'eventbox0225')
      .then(result => {
        this.loadAllData();
      }).catch(error => {
        this.$toast.error(this.$t('alert_unexpected_error'));
      });
    }
    setTimeout(() => {
      this.setIsMobile();
    }, 50);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
    if (this.snapMyInfo) {
      this.snapMyInfo();
    }
    if (this.snapEventList) {
      this.snapEventList();
    }
    if (this.snapMyBlockList) {
      this.snapMyBlockList();
    }
    if (this.snapServiceCharge) {
      this.snapServiceCharge();
    }
    if (this.snapEmployeeList) {
      this.snapEmployeeList();
    }
  },
  methods: {
    onResize() {
      this.setIsMobile();
    },
    setIsMobile() {
      const divRoot = document.getElementById('divRoot');
      if (divRoot) {
        const clientWidth = divRoot.clientWidth;
        const isMobile = this.$isMobile() === true || clientWidth < 560;
        this.$store.commit('set', ['isMobile', isMobile]);
      }
    },
    loadAllData() {
      const myInfo = this.$store.state.myInfo;
      if (myInfo) {
        this.getMyInfoTask();
        setTimeout(() => {
          this.getMyBlockListTask();
        }, 100);
        setTimeout(() => {
          this.getEventListTask();
        }, 300);
        if (myInfo.userType === this.USER_ORGANIZER) {
          setTimeout(() => {
            this.getEmployeeListTask();
          }, 700);
        }
      } else {
        this.getEventListTask();
      }
      setTimeout(() => {
        this.getServiceChargeTask();
      }, 500);
    },
    setAcceptedCooke() {
      this.acceptedCookie = new Date().getTime().toString();
      localStorage.setItem('eventboxAcceptedCookie', this.acceptedCookie);
    },
    clearMyInfo() {
      this.$store.commit('set', ['myId', '']);
      this.$store.commit('set', ['myInfo', null]);
      this.$store.commit('set', ['myBlockList', []]);
      localStorage.setItem('eventboxMyId', '');
      localStorage.setItem('eventboxMyInfo', '');
    },
    checkTestPrefix(id) {
      return window.location.hostname === 'localhost' || !id.startsWith(this.TEST_PREFIX);
    },
    isBlocked(eventInfo) {
      const myId = this.$store.state.myId;
      if (myId) {
        const myBlockList = this.$store.state.myBlockList;
        if (myBlockList.filter(element => element.targetType === 1 && element.targetId === eventInfo.userId).length) {
          return true;
        }
        if (myBlockList.filter(element => element.targetType === 3 && element.targetId === eventInfo.eventId).length) {
          return true;
        }
      }
      return false;
    },
    getMyInfoTask() {
      const myId = this.$store.state.myId;
      if (!myId || this.snapMyInfo) {
        return;
      }
      this.snapMyInfo = onSnapshot(doc(firestore, 'user', myId), doc => {
        const myInfo = doc.data();
        if (myInfo) {
          if (myInfo.deletedAt) {
            this.$toast.error(this.$t('alert_your_account_has_been_deleted'));
            this.clearMyInfo();
          } else if (this.$store.state.myInfo && this.$store.state.myInfo.password !== myInfo.password) {
            this.$toast.info(this.$t('alert_sign_in_again'));
            this.clearMyInfo();
            window.location.reload();
          } else {
            this.$store.commit('set', ['myInfo', myInfo]);
            localStorage.setItem('eventboxMyInfo', JSON.stringify(myInfo));
          }
        } else {
          this.clearMyInfo();
        }
      })
    },
    getEventListTask() {
      if (this.snapEventList) {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
        return;
      }
      const q = query(collection(firestore, 'event'), where('deletedAt', '==', null), where('userId', '!=', 'LPJhYAJkl6SBeboygzZN')); // Özbulut
      this.snapEventList = onSnapshot(q, querySnapshot => {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
        const eventList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (this.checkTestPrefix(data.eventId) && !this.isBlocked(data)) {
            if (data.userId === this.$store.state.myId || data.onlyVisibleToMe !== true) {
              eventList.push(data);
            }
          }
        });
        this.$store.commit('set', ['eventList', eventList]);
        localStorage.setItem('eventboxEventList', JSON.stringify(eventList));
        const liveEventList = eventList.filter(element => isFutureTimestamp(element.endAt)).sort((a, b) => ((b.netSales || 0) > (a.netSales || 0)) ? 1 : -1);
        this.$store.commit('set', ['liveEventList', liveEventList]);
      });
    },
    getMyBlockListTask() {
      const myId = this.$store.state.myId;
      if (!myId || this.snapMyBlockList) {
        return;
      }
      const q = query(collection(firestore, 'block'), where('userId', '==', myId));
      this.snapMyBlockList = onSnapshot(q, querySnapshot => {
        const myBlockList = [];
        querySnapshot.forEach(doc => myBlockList.push(doc.data()));
        this.$store.commit('set', ['myBlockList', myBlockList]);
      });
    },
    getServiceChargeTask() {
      if (this.snapServiceCharge) {
        return;
      }
      const q = query(collection(firestore, 'serviceCharge'), orderBy('createdAt', 'desc'), limit(1));
      this.snapServiceCharge = onSnapshot(q, querySnapshot => {
        var serviceChargeInfo = null;
        querySnapshot.forEach(doc => serviceChargeInfo = doc.data());
        this.$store.commit('set', ['serviceChargeInfo', serviceChargeInfo]);
      });
    },
    getEmployeeListTask() {
      const myId = this.$store.state.myId;
      if (!myId || this.snapEmployeeList) {
        return;
      }
      const q = query(collection(firestore, 'employee'), where('userId', '==', myId));
      this.snapEmployeeList = onSnapshot(q, querySnapshot => {
        const employeeList = [];
        querySnapshot.forEach(doc => employeeList.push(doc.data()));
        this.$store.commit('set', ['employeeList', employeeList]);
      });
    }
  }
};
</script>